<template>
<div>
    <div class="lead-search-area">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="lead-search">
                    <div class="form-group">
                        <label for="type">Type</label>
                        <select class="form-control" id="type" v-model="filters_.type">
                            <option value="">All</option>
                            <option value="Booking">Booking</option>
                            <option value="Queue">Queue</option>
                        </select>
                        <!-- <select class="form-control" id="by" v-model="filters_.employee">
                            <option value="">All</option>
                            <option v-for="employee in employees" :key="employee.id" :value="employee">{{employee.user.name}}</option>
                        </select> -->
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="lead-search">
                    <div class="form-group">
                        <label>Start</label>
                        <Datepicker v-model="filters_.start_date" :enableTimePicker="false" :autoApply="true" placeholder="Select Start Date" inputClassName="form-control date-controll "></Datepicker>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="lead-search">
                    <div class="form-group">
                        <label>End</label>
                        <Datepicker v-model="filters_.end_date" :enableTimePicker="false" :autoApply="true" placeholder="Select End Date" inputClassName="form-control date-controll "></Datepicker>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2">
                <div class="lead-search">
                    <a class="refresh" v-on:click.prevent="filterData" href="#"><img class="img-fluid" src="@/assets/images/icons/refresh.svg" alt="">Refresh</a>
                </div>
            </div>
        </div>
    </div>
    <!-- ounter-grid -->
    <div class="counter-grid">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="counters">
                    <div class="thamnils">
                        <svg class="icons mt-1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 20 20" fill="none">
                            <path d="M4.65274 7.91668L2.17452 9.26843C1.59547 9.58428 1.59547 10.4157 2.17452 10.7316L4.65274 12.0833M4.65274 7.91668L9.20187 10.398C9.69934 10.6694 10.3006 10.6694 10.798 10.398L15.3472 7.91668M4.65274 7.91668L2.17452 6.56492C1.59547 6.24907 1.59547 5.41761 2.17452 5.10176L9.20188 1.26866C9.69934 0.997319 10.3006 0.997319 10.798 1.26866L17.8254 5.10176C18.4045 5.41761 18.4045 6.24907 17.8254 6.56492L15.3472 7.91668M15.3472 7.91668L17.8254 9.26843C18.4045 9.58428 18.4045 10.4157 17.8254 10.7316L15.3472 12.0833M4.65274 12.0833L2.17452 13.4351C1.59547 13.7509 1.59547 14.5824 2.17452 14.8983L9.20187 18.7314C9.69934 19.0027 10.3006 19.0027 10.798 18.7314L17.8254 14.8983C18.4045 14.5824 18.4045 13.7509 17.8254 13.4351L15.3472 12.0833M4.65274 12.0833L9.20187 14.5647C9.69934 14.836 10.3006 14.836 10.798 14.5647L15.3472 12.0833" stroke="#6e317a" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div class="counter-num">
                        <p class="mb-0">NET DEPOSIT</p>
                        <h4>{{stats.net_sale}}</h4>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="counters">
                    <div class="thamnils">
                        <svg class="icons mt-1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 20 20" fill="none">
                            <path d="M4.65274 7.91668L2.17452 9.26843C1.59547 9.58428 1.59547 10.4157 2.17452 10.7316L4.65274 12.0833M4.65274 7.91668L9.20187 10.398C9.69934 10.6694 10.3006 10.6694 10.798 10.398L15.3472 7.91668M4.65274 7.91668L2.17452 6.56492C1.59547 6.24907 1.59547 5.41761 2.17452 5.10176L9.20188 1.26866C9.69934 0.997319 10.3006 0.997319 10.798 1.26866L17.8254 5.10176C18.4045 5.41761 18.4045 6.24907 17.8254 6.56492L15.3472 7.91668M15.3472 7.91668L17.8254 9.26843C18.4045 9.58428 18.4045 10.4157 17.8254 10.7316L15.3472 12.0833M4.65274 12.0833L2.17452 13.4351C1.59547 13.7509 1.59547 14.5824 2.17452 14.8983L9.20187 18.7314C9.69934 19.0027 10.3006 19.0027 10.798 18.7314L17.8254 14.8983C18.4045 14.5824 18.4045 13.7509 17.8254 13.4351L15.3472 12.0833M4.65274 12.0833L9.20187 14.5647C9.69934 14.836 10.3006 14.836 10.798 14.5647L15.3472 12.0833" stroke="#6e317a" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div class="counter-num">
                        <p class="mb-0">GROSS SALES</p>
                        <h4>{{stats.gross_sale}}</h4>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="counters">
                    <div class="thamnils">
                        <svg class="icons mt-1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 20 20" fill="none">
                            <path d="M4.65274 7.91668L2.17452 9.26843C1.59547 9.58428 1.59547 10.4157 2.17452 10.7316L4.65274 12.0833M4.65274 7.91668L9.20187 10.398C9.69934 10.6694 10.3006 10.6694 10.798 10.398L15.3472 7.91668M4.65274 7.91668L2.17452 6.56492C1.59547 6.24907 1.59547 5.41761 2.17452 5.10176L9.20188 1.26866C9.69934 0.997319 10.3006 0.997319 10.798 1.26866L17.8254 5.10176C18.4045 5.41761 18.4045 6.24907 17.8254 6.56492L15.3472 7.91668M15.3472 7.91668L17.8254 9.26843C18.4045 9.58428 18.4045 10.4157 17.8254 10.7316L15.3472 12.0833M4.65274 12.0833L2.17452 13.4351C1.59547 13.7509 1.59547 14.5824 2.17452 14.8983L9.20187 18.7314C9.69934 19.0027 10.3006 19.0027 10.798 18.7314L17.8254 14.8983C18.4045 14.5824 18.4045 13.7509 17.8254 13.4351L15.3472 12.0833M4.65274 12.0833L9.20187 14.5647C9.69934 14.836 10.3006 14.836 10.798 14.5647L15.3472 12.0833" stroke="#6e317a" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div class="counter-num">
                        <p class="mb-0">PAYE</p>
                        <h4>{{stats.paye}}</h4>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- ounter-grid -->


    <div class="leads-table-area addcustomer-table">
        <table id="transactions_table" class="display">
            <thead>
                <tr>
                    <th>Transaction ID</th>
                    <th>Ref ID</th>
                    <th>Customer</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>Date/Time</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import axios from "axios";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
//import moment from "moment";

export default {
    name: "Transactions",
    components: {
        Datepicker
    },
    data() {
        return {
            employees:[],
            filters_:{
                start_date:null,
                end_date:null,
                employee:'',
                type:''
            },
            stats:{}
        };
    },
    created() {
        this.getEmployees()
    },
    methods: {
        getEmployees(){
            let thiss = this
            axios.get('get_employees_list',{params: { business_id: this.$storage.getStorageSync('business').active_business.id }}).then(function (response) {
                //console.log(response.data.data)
                thiss.employees = response.data.data
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message);
                }
            });
        },
        // getTransactions(){
        //     let thiss = this
        //     axios.get('transactions_list').then(function (response) {
        //         thiss.transactions = response.data.data
        //     })
        //     .catch(error => {
        //         if (error.response) {
        //             console.log(error.response.data.message);
        //         }
        //     });
        // },
        filterData() {
            $("#transactions_table").dataTable().fnDraw();
        }
    },
    mounted() {
        let thiss = this;
        $("#transactions_table")
        //.addClass("nowrap")
        .dataTable({
            //order: [[7, "desc"]],
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
                url: axios.defaults.baseURL + "dt_transactions",
                type: "POST",
                data: function (d) {
                    d.start_date = thiss.filters_.start_date
                    d.end_date = thiss.filters_.end_date
                    d.employee = thiss.filters_.employee
                    d.type = thiss.filters_.type
                    d.business_id = thiss.$storage.getStorageSync('business').active_business.id
                },
                headers: {
                    Authorization: axios.defaults.headers.common['Authorization'],
                }
            },
            searching: false,
            drawCallback: function (response) {
                //alert( 'Table redrawn '+new Date() );
                thiss.stats = response.json.stats
                //console.log(response.json.stats)
            },
            columnDefs: [
                {
                    targets: [2, 4],
                    orderable: false,
                },
            ],
            //columnDefs: [{ targets: [-1, -3], className: 'dt-body-right' }]
        });
    },
};
</script>
<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>
<style>
.lead-search .date-controll{
    background-color:#fff !important;
    padding-left: 30px;
}
.dp__main{
    width: 100%;
}
.dp__input_icon{
    left: 13px;
}
.dp__clear_icon{
    right: -15px;
}
.counter-num h4{
    font-size: 25px;
}
</style>