<template>
    <AppContainer>
        <Transactions/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import Transactions from '@/components/dashboard/transactions/Transactions.vue'
export default {
  name: 'TransactionsList',
  components: {
    AppContainer,
    Transactions
  }
}
</script>